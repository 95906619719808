import React from "react"
import "./src/styles/global.css"
import wrapWithProvider from "./wrap-with-provider"
import { GoogleOAuthProvider } from "@react-oauth/google"

import Layout from "./src/components/layouts/index"
import { CurrencyProvider } from "./src/context/Currency"
export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element, props }) => {
  return (
    <CurrencyProvider>
      <GoogleOAuthProvider clientId="931514921901-skbvn06uvn279kjm12thuarqlm4usf2n.apps.googleusercontent.com">
        <Layout {...props}>{element}</Layout>
      </GoogleOAuthProvider>
    </CurrencyProvider>
  )
}

export const onRouteUpdate = function () {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq("track", "PageView")
  }
}
