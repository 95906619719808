import React from "react"
import { Provider } from "react-redux"

import store from "./src/state/createStore"

import { GraphQLClient, ClientContext } from 'graphql-hooks';

const client = new GraphQLClient({
  url: 'https://cms.rentalls.com/graphql',
});

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return  (
    <ClientContext.Provider value={client}>
      <Provider store={store}>
        {element}
      </Provider>
    </ClientContext.Provider>
  )
}