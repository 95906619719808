module.exports = [
  {
    default: true,
    path: `en`,
    label: `en`,
    locale: `en`,
  },
  {
    path: `sk`,
    label: `sk`,
    locale: `sk`,
  },
  {
    path: `cz`,
    label: `cz`,
    locale: `cs-CZ`,
  },
];
